import * as GQL from "./generated";
import {ApolloClient} from "@apollo/client";

function memberGetMe(apollo: ApolloClient<any>) {
    return apollo.query<GQL.GetMeQuery, GQL.GetMeQueryVariables>({
        query: GQL.GetMeDocument,
    });
}

function contentGetContents(apollo: ApolloClient<any>, tagCode: string) {
    return apollo.query<GQL.GetContentsQuery, GQL.GetContentsQueryVariables>({
        query: GQL.GetContentsDocument,
        variables: {tagCode, limit: 1000, offset: 0}
    });
}

export const ApolloQueryHelper = {
    memberGetMe,
    contentGetContents,
};
