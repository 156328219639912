/* eslint-disable */
/* tslint:disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: string; output: string; }
  DateTime: { input: string; output: string; }
  JSON: { input: any; output: any; }
  UUID: { input: string; output: string; }
};

export type Advert = {
  __typename?: 'Advert';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  phone: Scalars['String']['output'];
  photo: Maybe<File>;
  postedAt: Scalars['DateTime']['output'];
  postedBy: UserPublic;
  title: Scalars['String']['output'];
};

export type Attendance = {
  __typename?: 'Attendance';
  attendanceStatus: Maybe<AttendanceStatus>;
  description: Maybe<Scalars['String']['output']>;
  excusedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  member: Member;
  training: Training;
};

export type AttendanceReport = {
  __typename?: 'AttendanceReport';
  id: Scalars['String']['output'];
  memberAttendances: Array<MemberAttendances>;
  members: Array<Member>;
  trainings: Array<Training>;
};

export enum AttendanceStatus {
  Excused = 'EXCUSED',
  Ill = 'ILL',
  NotExcused = 'NOT_EXCUSED',
  Present = 'PRESENT'
}

export type AuthResult = {
  __typename?: 'AuthResult';
  accessToken: Scalars['String']['output'];
};

export type Camp = {
  __typename?: 'Camp';
  dateFrom: Maybe<Scalars['Date']['output']>;
  dateTo: Maybe<Scalars['Date']['output']>;
  description: Maybe<Scalars['String']['output']>;
  groups: Array<Group>;
  id: Scalars['Int']['output'];
  place: Maybe<Scalars['String']['output']>;
};

export type Category = {
  __typename?: 'Category';
  ageFrom: Scalars['Int']['output'];
  ageTo: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type Configuration = {
  __typename?: 'Configuration';
  addressCity: Scalars['String']['output'];
  addressStreet: Scalars['String']['output'];
  addressZip: Scalars['String']['output'];
  bankAccountNumber: Scalars['String']['output'];
  claim1: Scalars['String']['output'];
  claim2: Scalars['String']['output'];
  claim3: Scalars['String']['output'];
  claim4: Scalars['String']['output'];
  descriptionMeta: Scalars['String']['output'];
  events: Array<Event>;
  facebook: Maybe<Scalars['String']['output']>;
  h1Subtitle: Scalars['String']['output'];
  h1Title: Scalars['String']['output'];
  headingImage: Maybe<Image>;
  id: Scalars['Int']['output'];
  identificationNumber: Scalars['String']['output'];
  instagram: Maybe<Scalars['String']['output']>;
  logo: Maybe<File>;
  name: Scalars['String']['output'];
  officialName: Scalars['String']['output'];
  sponsors: Array<Sponsor>;
  teams: Array<Team>;
};

export type Content = {
  __typename?: 'Content';
  author: Maybe<ContentAuthor>;
  content: Scalars['JSON']['output'];
  createdAt: Maybe<Scalars['DateTime']['output']>;
  hidden: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  image: Maybe<File>;
  name: Scalars['String']['output'];
  perex: Scalars['String']['output'];
  publishedAt: Maybe<Scalars['DateTime']['output']>;
  seo: ContentSeo;
  tags: Array<ContentTag>;
  type: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type ContentAuthor = {
  __typename?: 'ContentAuthor';
  avatar: Maybe<File>;
  firstname: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  surname: Scalars['String']['output'];
};

export type ContentSeo = {
  __typename?: 'ContentSeo';
  description: Scalars['String']['output'];
  name: Maybe<Scalars['String']['output']>;
  ogDescription: Scalars['String']['output'];
  ogImage: Maybe<File>;
  ogTitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ContentTag = {
  __typename?: 'ContentTag';
  code: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
};

export type Event = {
  __typename?: 'Event';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  itemOrder: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type File = {
  __typename?: 'File';
  createdAt: Scalars['DateTime']['output'];
  extension: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  namespace: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export enum GroupMemberRole {
  Athlete = 'ATHLETE',
  HeadTrainer = 'HEAD_TRAINER',
  Trainer = 'TRAINER'
}

export type Image = {
  __typename?: 'Image';
  createdAt: Scalars['DateTime']['output'];
  extension: Scalars['String']['output'];
  height: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  namespace: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
  width: Scalars['Int']['output'];
};

export type Me = {
  __typename?: 'Me';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  lastLoginAt: Maybe<Scalars['DateTime']['output']>;
  members: Array<Member>;
  name: Scalars['String']['output'];
  profile: Profile;
  roles: Array<Scalars['String']['output']>;
  token: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  verified: Scalars['Boolean']['output'];
};

export type Member = {
  __typename?: 'Member';
  birthDate: Maybe<Scalars['Date']['output']>;
  czechSkiId: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  fisId: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  isHidden: Scalars['Boolean']['output'];
  memberFrom: Maybe<Scalars['Date']['output']>;
  memberTo: Maybe<Scalars['Date']['output']>;
  name: Scalars['String']['output'];
  personalId: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  surname: Scalars['String']['output'];
  variableSymbol: Maybe<Scalars['String']['output']>;
};

export type MemberAttendances = {
  __typename?: 'MemberAttendances';
  attendances: Array<Maybe<Attendance>>;
  id: Scalars['String']['output'];
  member: Member;
};

export type MemberInterests = {
  __typename?: 'MemberInterests';
  camps: Array<Camp>;
  currentGroup: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  races: Array<Race>;
  surname: Scalars['String']['output'];
  trainings: Array<TrainingWithAttendance>;
};

export type MemberTrainingAttendance = {
  __typename?: 'MemberTrainingAttendance';
  attendance: Maybe<Attendance>;
  id: Scalars['String']['output'];
  memberId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  surname: Scalars['String']['output'];
  trainingId: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addAdvert: Array<Advert>;
  deactivateAdvert: Array<Advert>;
  login: AuthResult;
  pairProfilesWithMembers: Scalars['Boolean']['output'];
  passwordSet: AuthResult;
  registration: Scalars['Boolean']['output'];
  setTrainingAttendance: Array<MemberTrainingAttendance>;
  unpairProfileMember: Scalars['Boolean']['output'];
};


export type MutationAddAdvertArgs = {
  description: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  photoId?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};


export type MutationDeactivateAdvertArgs = {
  advertId: Scalars['Int']['input'];
};


export type MutationLoginArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationPairProfilesWithMembersArgs = {
  memberIds: Array<Scalars['Int']['input']>;
  profileIds: Array<Scalars['Int']['input']>;
};


export type MutationPasswordSetArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationRegistrationArgs = {
  data: RegistrationInput;
};


export type MutationSetTrainingAttendanceArgs = {
  attendanceStatus: AttendanceStatus;
  memberId: Scalars['Int']['input'];
  trainingId: Scalars['Int']['input'];
};


export type MutationUnpairProfileMemberArgs = {
  memberId: Scalars['Int']['input'];
  profileId: Scalars['Int']['input'];
};

export type MyInterests = {
  __typename?: 'MyInterests';
  id: Scalars['Int']['output'];
  memberInterests: Array<MemberInterests>;
};

export type Profile = {
  __typename?: 'Profile';
  id: Scalars['Int']['output'];
  members: Array<Member>;
  name: Scalars['String']['output'];
  phone: Maybe<Scalars['String']['output']>;
  profilePhoto: Maybe<File>;
  surname: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  adverts: Array<Advert>;
  configuration: Configuration;
  content: Content;
  contents: Array<Content>;
  groupCamps: Array<Camp>;
  groupMembers: Array<Member>;
  groupMonthAttendanceReport: AttendanceReport;
  groupTrainings: Array<Training>;
  groups: Array<Group>;
  health: Scalars['Boolean']['output'];
  me: Me;
  members: Array<Member>;
  myInterests: MyInterests;
  races: Array<Race>;
  trainingAttendances: Array<MemberTrainingAttendance>;
  trainings: Array<Training>;
};


export type QueryAdvertsArgs = {
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryContentArgs = {
  content: Scalars['Int']['input'];
};


export type QueryContentsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  tagCode?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGroupCampsArgs = {
  groupId: Scalars['Int']['input'];
};


export type QueryGroupMembersArgs = {
  groupId: Scalars['Int']['input'];
  groupMemberRole: GroupMemberRole;
};


export type QueryGroupMonthAttendanceReportArgs = {
  groupId: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};


export type QueryGroupTrainingsArgs = {
  groupId: Scalars['Int']['input'];
};


export type QueryRacesArgs = {
  groupId: Scalars['Int']['input'];
};


export type QueryTrainingAttendancesArgs = {
  trainingId: Scalars['Int']['input'];
};

export type Race = {
  __typename?: 'Race';
  categories: Array<Category>;
  dateFrom: Scalars['Date']['output'];
  dateTo: Maybe<Scalars['Date']['output']>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  url: Maybe<Scalars['String']['output']>;
};

export type RegistrationInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  surname: Scalars['String']['input'];
};

export type Sponsor = {
  __typename?: 'Sponsor';
  id: Scalars['Int']['output'];
  itemOrder: Scalars['Int']['output'];
  logo: Maybe<File>;
  mainSponsor: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Team = {
  __typename?: 'Team';
  email: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  itemOrder: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  photo: Maybe<Image>;
  role: Maybe<Scalars['String']['output']>;
};

export type Training = {
  __typename?: 'Training';
  activity: Maybe<Scalars['String']['output']>;
  datetimeFrom: Maybe<Scalars['DateTime']['output']>;
  durationMinutes: Maybe<Scalars['Int']['output']>;
  group: Group;
  id: Scalars['Int']['output'];
  place: Maybe<Scalars['String']['output']>;
};

export type TrainingWithAttendance = {
  __typename?: 'TrainingWithAttendance';
  activity: Maybe<Scalars['String']['output']>;
  attendanceStatus: Maybe<AttendanceStatus>;
  datetimeFrom: Maybe<Scalars['DateTime']['output']>;
  durationMinutes: Maybe<Scalars['Int']['output']>;
  group: Group;
  id: Scalars['Int']['output'];
  place: Maybe<Scalars['String']['output']>;
};

export type UserPublic = {
  __typename?: 'UserPublic';
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type AdvertFragment = { __typename?: 'Advert', id: number, postedAt: string, title: string, description: string, phone: string, isActive: boolean, postedBy: { __typename?: 'UserPublic', id: number, isActive: boolean, email: string, name: string }, photo: { __typename?: 'File', id: number, uuid: string, type: string, extension: string, name: string, namespace: string } | null };

export type AddAdvertMutationVariables = Exact<{
  title: Scalars['String']['input'];
  description: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  photoId: InputMaybe<Scalars['Int']['input']>;
}>;


export type AddAdvertMutation = { __typename?: 'Mutation', addAdvert: Array<{ __typename?: 'Advert', id: number, postedAt: string, title: string, description: string, phone: string, isActive: boolean, postedBy: { __typename?: 'UserPublic', id: number, isActive: boolean, email: string, name: string }, photo: { __typename?: 'File', id: number, uuid: string, type: string, extension: string, name: string, namespace: string } | null }> };

export type DeactivateAdvertMutationVariables = Exact<{
  advertId: Scalars['Int']['input'];
}>;


export type DeactivateAdvertMutation = { __typename?: 'Mutation', deactivateAdvert: Array<{ __typename?: 'Advert', id: number, postedAt: string, title: string, description: string, phone: string, isActive: boolean, postedBy: { __typename?: 'UserPublic', id: number, isActive: boolean, email: string, name: string }, photo: { __typename?: 'File', id: number, uuid: string, type: string, extension: string, name: string, namespace: string } | null }> };

export type GetAdvertsQueryVariables = Exact<{
  onlyActive: Scalars['Boolean']['input'];
}>;


export type GetAdvertsQuery = { __typename?: 'Query', adverts: Array<{ __typename?: 'Advert', id: number, postedAt: string, title: string, description: string, phone: string, isActive: boolean, postedBy: { __typename?: 'UserPublic', id: number, isActive: boolean, email: string, name: string }, photo: { __typename?: 'File', id: number, uuid: string, type: string, extension: string, name: string, namespace: string } | null }> };

export type AuthResultFragment = { __typename?: 'AuthResult', accessToken: string };

export type RegistrationMutationVariables = Exact<{
  data: RegistrationInput;
}>;


export type RegistrationMutation = { __typename?: 'Mutation', registration: boolean };

export type LoginMutationVariables = Exact<{
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthResult', accessToken: string } };

export type PasswordSetMutationVariables = Exact<{
  token: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type PasswordSetMutation = { __typename?: 'Mutation', passwordSet: { __typename?: 'AuthResult', accessToken: string } };

export type ContentAuthorFragment = { __typename?: 'ContentAuthor', id: number, firstname: string, surname: string, avatar: { __typename?: 'File', id: number, uuid: string, type: string, extension: string, name: string, namespace: string } | null };

export type ContentTagFragment = { __typename?: 'ContentTag', id: number, code: string, label: string };

export type ContentSeoFragment = { __typename?: 'ContentSeo', name: string | null, title: string, description: string, ogTitle: string, ogDescription: string, ogImage: { __typename?: 'File', id: number, uuid: string, type: string, extension: string, name: string, namespace: string } | null };

export type ContentFragment = { __typename?: 'Content', id: number, type: string, name: string, perex: string, hidden: boolean, publishedAt: string | null, createdAt: string | null, updatedAt: string | null, content: any, seo: { __typename?: 'ContentSeo', name: string | null, title: string, description: string, ogTitle: string, ogDescription: string, ogImage: { __typename?: 'File', id: number, uuid: string, type: string, extension: string, name: string, namespace: string } | null }, image: { __typename?: 'File', id: number, uuid: string, type: string, extension: string, name: string, namespace: string } | null, author: { __typename?: 'ContentAuthor', id: number, firstname: string, surname: string, avatar: { __typename?: 'File', id: number, uuid: string, type: string, extension: string, name: string, namespace: string } | null } | null, tags: Array<{ __typename?: 'ContentTag', id: number, code: string, label: string }> };

export type GetContentsQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  tagCode: InputMaybe<Scalars['String']['input']>;
}>;


export type GetContentsQuery = { __typename?: 'Query', contents: Array<{ __typename?: 'Content', id: number, type: string, name: string, perex: string, hidden: boolean, publishedAt: string | null, createdAt: string | null, updatedAt: string | null, content: any, seo: { __typename?: 'ContentSeo', name: string | null, title: string, description: string, ogTitle: string, ogDescription: string, ogImage: { __typename?: 'File', id: number, uuid: string, type: string, extension: string, name: string, namespace: string } | null }, image: { __typename?: 'File', id: number, uuid: string, type: string, extension: string, name: string, namespace: string } | null, author: { __typename?: 'ContentAuthor', id: number, firstname: string, surname: string, avatar: { __typename?: 'File', id: number, uuid: string, type: string, extension: string, name: string, namespace: string } | null } | null, tags: Array<{ __typename?: 'ContentTag', id: number, code: string, label: string }> }> };

export type GetContentQueryVariables = Exact<{
  contentId: Scalars['Int']['input'];
}>;


export type GetContentQuery = { __typename?: 'Query', content: { __typename?: 'Content', id: number, type: string, name: string, perex: string, hidden: boolean, publishedAt: string | null, createdAt: string | null, updatedAt: string | null, content: any, seo: { __typename?: 'ContentSeo', name: string | null, title: string, description: string, ogTitle: string, ogDescription: string, ogImage: { __typename?: 'File', id: number, uuid: string, type: string, extension: string, name: string, namespace: string } | null }, image: { __typename?: 'File', id: number, uuid: string, type: string, extension: string, name: string, namespace: string } | null, author: { __typename?: 'ContentAuthor', id: number, firstname: string, surname: string, avatar: { __typename?: 'File', id: number, uuid: string, type: string, extension: string, name: string, namespace: string } | null } | null, tags: Array<{ __typename?: 'ContentTag', id: number, code: string, label: string }> } };

export type MeFragment = { __typename?: 'Me', id: number, email: string, isActive: boolean, verified: boolean, token: string | null, name: string, createdAt: string, updatedAt: string | null, lastLoginAt: string | null, roles: Array<string>, profile: { __typename?: 'Profile', id: number, name: string, surname: string, phone: string | null, profilePhoto: { __typename?: 'File', id: number, uuid: string, type: string, extension: string, name: string, namespace: string } | null, members: Array<{ __typename?: 'Member', id: number, name: string, surname: string, email: string | null, phone: string | null, birthDate: string | null, personalId: string | null, czechSkiId: string | null, fisId: string | null, memberFrom: string | null, memberTo: string | null, variableSymbol: string | null, isActive: boolean, isHidden: boolean }> } };

export type ProfileFragment = { __typename?: 'Profile', id: number, name: string, surname: string, phone: string | null, profilePhoto: { __typename?: 'File', id: number, uuid: string, type: string, extension: string, name: string, namespace: string } | null, members: Array<{ __typename?: 'Member', id: number, name: string, surname: string, email: string | null, phone: string | null, birthDate: string | null, personalId: string | null, czechSkiId: string | null, fisId: string | null, memberFrom: string | null, memberTo: string | null, variableSymbol: string | null, isActive: boolean, isHidden: boolean }> };

export type MemberFragment = { __typename?: 'Member', id: number, name: string, surname: string, email: string | null, phone: string | null, birthDate: string | null, personalId: string | null, czechSkiId: string | null, fisId: string | null, memberFrom: string | null, memberTo: string | null, variableSymbol: string | null, isActive: boolean, isHidden: boolean };

export type UserPublicFragment = { __typename?: 'UserPublic', id: number, isActive: boolean, email: string, name: string };

export type MyInterestsFragment = { __typename?: 'MyInterests', id: number, memberInterests: Array<{ __typename?: 'MemberInterests', id: number, name: string, surname: string, currentGroup: string, trainings: Array<{ __typename?: 'TrainingWithAttendance', id: number, datetimeFrom: string | null, durationMinutes: number | null, place: string | null, activity: string | null, attendanceStatus: AttendanceStatus | null, group: { __typename?: 'Group', id: number, name: string } }>, races: Array<{ __typename?: 'Race', id: number, name: string, dateFrom: string, dateTo: string | null, description: string | null, url: string | null }>, camps: Array<{ __typename?: 'Camp', id: number, dateFrom: string | null, dateTo: string | null, place: string | null, description: string | null, groups: Array<{ __typename?: 'Group', id: number, name: string }> }> }> };

export type MemberInterestsFragment = { __typename?: 'MemberInterests', id: number, name: string, surname: string, currentGroup: string, trainings: Array<{ __typename?: 'TrainingWithAttendance', id: number, datetimeFrom: string | null, durationMinutes: number | null, place: string | null, activity: string | null, attendanceStatus: AttendanceStatus | null, group: { __typename?: 'Group', id: number, name: string } }>, races: Array<{ __typename?: 'Race', id: number, name: string, dateFrom: string, dateTo: string | null, description: string | null, url: string | null }>, camps: Array<{ __typename?: 'Camp', id: number, dateFrom: string | null, dateTo: string | null, place: string | null, description: string | null, groups: Array<{ __typename?: 'Group', id: number, name: string }> }> };

export type PairProfilesWithMembersMutationVariables = Exact<{
  profileIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  memberIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type PairProfilesWithMembersMutation = { __typename?: 'Mutation', pairProfilesWithMembers: boolean };

export type UnpairProfileMemberMutationVariables = Exact<{
  profileId: Scalars['Int']['input'];
  memberId: Scalars['Int']['input'];
}>;


export type UnpairProfileMemberMutation = { __typename?: 'Mutation', unpairProfileMember: boolean };

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = { __typename?: 'Query', me: { __typename?: 'Me', id: number, email: string, isActive: boolean, verified: boolean, token: string | null, name: string, createdAt: string, updatedAt: string | null, lastLoginAt: string | null, roles: Array<string>, profile: { __typename?: 'Profile', id: number, name: string, surname: string, phone: string | null, profilePhoto: { __typename?: 'File', id: number, uuid: string, type: string, extension: string, name: string, namespace: string } | null, members: Array<{ __typename?: 'Member', id: number, name: string, surname: string, email: string | null, phone: string | null, birthDate: string | null, personalId: string | null, czechSkiId: string | null, fisId: string | null, memberFrom: string | null, memberTo: string | null, variableSymbol: string | null, isActive: boolean, isHidden: boolean }> } } };

export type GetGroupMembersQueryVariables = Exact<{
  groupId: Scalars['Int']['input'];
  groupMemberRole: GroupMemberRole;
}>;


export type GetGroupMembersQuery = { __typename?: 'Query', groupMembers: Array<{ __typename?: 'Member', id: number, name: string, surname: string, email: string | null, phone: string | null, birthDate: string | null, personalId: string | null, czechSkiId: string | null, fisId: string | null, memberFrom: string | null, memberTo: string | null, variableSymbol: string | null, isActive: boolean, isHidden: boolean }> };

export type GetMembersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMembersQuery = { __typename?: 'Query', members: Array<{ __typename?: 'Member', id: number, name: string, surname: string, email: string | null, phone: string | null, birthDate: string | null, personalId: string | null, czechSkiId: string | null, fisId: string | null, memberFrom: string | null, memberTo: string | null, variableSymbol: string | null, isActive: boolean, isHidden: boolean }> };

export type GetMyInterestsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyInterestsQuery = { __typename?: 'Query', myInterests: { __typename?: 'MyInterests', id: number, memberInterests: Array<{ __typename?: 'MemberInterests', id: number, name: string, surname: string, currentGroup: string, trainings: Array<{ __typename?: 'TrainingWithAttendance', id: number, datetimeFrom: string | null, durationMinutes: number | null, place: string | null, activity: string | null, attendanceStatus: AttendanceStatus | null, group: { __typename?: 'Group', id: number, name: string } }>, races: Array<{ __typename?: 'Race', id: number, name: string, dateFrom: string, dateTo: string | null, description: string | null, url: string | null }>, camps: Array<{ __typename?: 'Camp', id: number, dateFrom: string | null, dateTo: string | null, place: string | null, description: string | null, groups: Array<{ __typename?: 'Group', id: number, name: string }> }> }> } };

export type RaceFragment = { __typename?: 'Race', id: number, name: string, dateFrom: string, dateTo: string | null, description: string | null, url: string | null };

export type GetRacesQueryVariables = Exact<{
  groupId: Scalars['Int']['input'];
}>;


export type GetRacesQuery = { __typename?: 'Query', races: Array<{ __typename?: 'Race', id: number, name: string, dateFrom: string, dateTo: string | null, description: string | null, url: string | null }> };

export type FileFragment = { __typename?: 'File', id: number, uuid: string, type: string, extension: string, name: string, namespace: string };

export type ImageFragment = { __typename?: 'Image', id: number, uuid: string, type: string, extension: string, name: string, namespace: string, width: number, height: number };

export type TrainingFragment = { __typename?: 'Training', id: number, datetimeFrom: string | null, durationMinutes: number | null, place: string | null, activity: string | null, group: { __typename?: 'Group', id: number, name: string } };

export type TrainingWithAttendanceFragment = { __typename?: 'TrainingWithAttendance', id: number, datetimeFrom: string | null, durationMinutes: number | null, place: string | null, activity: string | null, attendanceStatus: AttendanceStatus | null, group: { __typename?: 'Group', id: number, name: string } };

export type CampFragment = { __typename?: 'Camp', id: number, dateFrom: string | null, dateTo: string | null, place: string | null, description: string | null, groups: Array<{ __typename?: 'Group', id: number, name: string }> };

export type GroupFragment = { __typename?: 'Group', id: number, name: string };

export type MemberTrainingAttendanceFragment = { __typename?: 'MemberTrainingAttendance', id: string, memberId: number, trainingId: number, name: string, surname: string, attendance: { __typename?: 'Attendance', id: number, description: string | null, attendanceStatus: AttendanceStatus | null, excusedAt: string | null } | null };

export type AttendanceFragment = { __typename?: 'Attendance', id: number, description: string | null, attendanceStatus: AttendanceStatus | null, excusedAt: string | null };

export type AttendanceReportFragment = { __typename?: 'AttendanceReport', id: string, trainings: Array<{ __typename?: 'Training', id: number, datetimeFrom: string | null, durationMinutes: number | null, place: string | null, activity: string | null, group: { __typename?: 'Group', id: number, name: string } }>, memberAttendances: Array<{ __typename?: 'MemberAttendances', id: string, member: { __typename?: 'Member', id: number, name: string, surname: string, email: string | null, phone: string | null, birthDate: string | null, personalId: string | null, czechSkiId: string | null, fisId: string | null, memberFrom: string | null, memberTo: string | null, variableSymbol: string | null, isActive: boolean, isHidden: boolean }, attendances: Array<{ __typename?: 'Attendance', id: number, description: string | null, attendanceStatus: AttendanceStatus | null, excusedAt: string | null } | null> }> };

export type MemberAttendancesFragment = { __typename?: 'MemberAttendances', id: string, member: { __typename?: 'Member', id: number, name: string, surname: string, email: string | null, phone: string | null, birthDate: string | null, personalId: string | null, czechSkiId: string | null, fisId: string | null, memberFrom: string | null, memberTo: string | null, variableSymbol: string | null, isActive: boolean, isHidden: boolean }, attendances: Array<{ __typename?: 'Attendance', id: number, description: string | null, attendanceStatus: AttendanceStatus | null, excusedAt: string | null } | null> };

export type SetTrainingAttendanceMutationVariables = Exact<{
  trainingId: Scalars['Int']['input'];
  memberId: Scalars['Int']['input'];
  attendanceStatus: AttendanceStatus;
}>;


export type SetTrainingAttendanceMutation = { __typename?: 'Mutation', setTrainingAttendance: Array<{ __typename?: 'MemberTrainingAttendance', id: string, memberId: number, trainingId: number, name: string, surname: string, attendance: { __typename?: 'Attendance', id: number, description: string | null, attendanceStatus: AttendanceStatus | null, excusedAt: string | null } | null }> };

export type GetTrainingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTrainingsQuery = { __typename?: 'Query', trainings: Array<{ __typename?: 'Training', id: number, datetimeFrom: string | null, durationMinutes: number | null, place: string | null, activity: string | null, group: { __typename?: 'Group', id: number, name: string } }> };

export type GetGroupTrainingsQueryVariables = Exact<{
  groupId: Scalars['Int']['input'];
}>;


export type GetGroupTrainingsQuery = { __typename?: 'Query', groupTrainings: Array<{ __typename?: 'Training', id: number, datetimeFrom: string | null, durationMinutes: number | null, place: string | null, activity: string | null, group: { __typename?: 'Group', id: number, name: string } }> };

export type GetGroupCampsQueryVariables = Exact<{
  groupId: Scalars['Int']['input'];
}>;


export type GetGroupCampsQuery = { __typename?: 'Query', groupCamps: Array<{ __typename?: 'Camp', id: number, dateFrom: string | null, dateTo: string | null, place: string | null, description: string | null, groups: Array<{ __typename?: 'Group', id: number, name: string }> }> };

export type GetGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGroupsQuery = { __typename?: 'Query', groups: Array<{ __typename?: 'Group', id: number, name: string }> };

export type GetTrainingAttendancesQueryVariables = Exact<{
  trainingId: Scalars['Int']['input'];
}>;


export type GetTrainingAttendancesQuery = { __typename?: 'Query', trainingAttendances: Array<{ __typename?: 'MemberTrainingAttendance', id: string, memberId: number, trainingId: number, name: string, surname: string, attendance: { __typename?: 'Attendance', id: number, description: string | null, attendanceStatus: AttendanceStatus | null, excusedAt: string | null } | null }> };

export type GetGroupMonthAttendanceReportQueryVariables = Exact<{
  groupId: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
}>;


export type GetGroupMonthAttendanceReportQuery = { __typename?: 'Query', groupMonthAttendanceReport: { __typename?: 'AttendanceReport', id: string, trainings: Array<{ __typename?: 'Training', id: number, datetimeFrom: string | null, durationMinutes: number | null, place: string | null, activity: string | null, group: { __typename?: 'Group', id: number, name: string } }>, memberAttendances: Array<{ __typename?: 'MemberAttendances', id: string, member: { __typename?: 'Member', id: number, name: string, surname: string, email: string | null, phone: string | null, birthDate: string | null, personalId: string | null, czechSkiId: string | null, fisId: string | null, memberFrom: string | null, memberTo: string | null, variableSymbol: string | null, isActive: boolean, isHidden: boolean }, attendances: Array<{ __typename?: 'Attendance', id: number, description: string | null, attendanceStatus: AttendanceStatus | null, excusedAt: string | null } | null> }> } };

export type ConfigurationFragment = { __typename?: 'Configuration', id: number, name: string, officialName: string, descriptionMeta: string, h1Title: string, h1Subtitle: string, claim1: string, claim2: string, claim3: string, claim4: string, addressStreet: string, addressCity: string, addressZip: string, identificationNumber: string, bankAccountNumber: string, instagram: string | null, facebook: string | null, headingImage: { __typename?: 'Image', id: number, uuid: string, type: string, extension: string, name: string, namespace: string, width: number, height: number } | null, logo: { __typename?: 'File', id: number, uuid: string, type: string, extension: string, name: string, namespace: string } | null, events: Array<{ __typename?: 'Event', id: number, itemOrder: number, name: string, description: string }>, sponsors: Array<{ __typename?: 'Sponsor', id: number, itemOrder: number, name: string, url: string, mainSponsor: boolean, logo: { __typename?: 'File', id: number, uuid: string, type: string, extension: string, name: string, namespace: string } | null }>, teams: Array<{ __typename?: 'Team', id: number, itemOrder: number, name: string, role: string | null, email: string | null, photo: { __typename?: 'Image', id: number, uuid: string, type: string, extension: string, name: string, namespace: string, width: number, height: number } | null }> };

export type EventFragment = { __typename?: 'Event', id: number, itemOrder: number, name: string, description: string };

export type SponsorFragment = { __typename?: 'Sponsor', id: number, itemOrder: number, name: string, url: string, mainSponsor: boolean, logo: { __typename?: 'File', id: number, uuid: string, type: string, extension: string, name: string, namespace: string } | null };

export type TeamFragment = { __typename?: 'Team', id: number, itemOrder: number, name: string, role: string | null, email: string | null, photo: { __typename?: 'Image', id: number, uuid: string, type: string, extension: string, name: string, namespace: string, width: number, height: number } | null };

export type GetConfigurationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConfigurationQuery = { __typename?: 'Query', configuration: { __typename?: 'Configuration', id: number, name: string, officialName: string, descriptionMeta: string, h1Title: string, h1Subtitle: string, claim1: string, claim2: string, claim3: string, claim4: string, addressStreet: string, addressCity: string, addressZip: string, identificationNumber: string, bankAccountNumber: string, instagram: string | null, facebook: string | null, headingImage: { __typename?: 'Image', id: number, uuid: string, type: string, extension: string, name: string, namespace: string, width: number, height: number } | null, logo: { __typename?: 'File', id: number, uuid: string, type: string, extension: string, name: string, namespace: string } | null, events: Array<{ __typename?: 'Event', id: number, itemOrder: number, name: string, description: string }>, sponsors: Array<{ __typename?: 'Sponsor', id: number, itemOrder: number, name: string, url: string, mainSponsor: boolean, logo: { __typename?: 'File', id: number, uuid: string, type: string, extension: string, name: string, namespace: string } | null }>, teams: Array<{ __typename?: 'Team', id: number, itemOrder: number, name: string, role: string | null, email: string | null, photo: { __typename?: 'Image', id: number, uuid: string, type: string, extension: string, name: string, namespace: string, width: number, height: number } | null }> } };

export const UserPublicFragmentDoc = gql`
    fragment UserPublic on UserPublic {
  id
  isActive
  email
  name
}
    `;
export const FileFragmentDoc = gql`
    fragment File on File {
  id
  uuid
  type
  extension
  name
  namespace
}
    `;
export const AdvertFragmentDoc = gql`
    fragment Advert on Advert {
  id
  postedBy {
    ...UserPublic
  }
  postedAt
  title
  description
  phone
  isActive
  photo {
    ...File
  }
}
    ${UserPublicFragmentDoc}
${FileFragmentDoc}`;
export const AuthResultFragmentDoc = gql`
    fragment AuthResult on AuthResult {
  accessToken
}
    `;
export const ContentSeoFragmentDoc = gql`
    fragment ContentSeo on ContentSeo {
  name
  title
  description
  ogTitle
  ogDescription
  ogImage {
    ...File
  }
}
    ${FileFragmentDoc}`;
export const ContentAuthorFragmentDoc = gql`
    fragment ContentAuthor on ContentAuthor {
  id
  firstname
  surname
  avatar {
    ...File
  }
}
    ${FileFragmentDoc}`;
export const ContentTagFragmentDoc = gql`
    fragment ContentTag on ContentTag {
  id
  code
  label
}
    `;
export const ContentFragmentDoc = gql`
    fragment Content on Content {
  id
  type
  name
  perex
  seo {
    ...ContentSeo
  }
  hidden
  image {
    ...File
  }
  publishedAt
  author {
    ...ContentAuthor
  }
  createdAt
  updatedAt
  content
  tags {
    ...ContentTag
  }
}
    ${ContentSeoFragmentDoc}
${FileFragmentDoc}
${ContentAuthorFragmentDoc}
${ContentTagFragmentDoc}`;
export const MemberFragmentDoc = gql`
    fragment Member on Member {
  id
  name
  surname
  email
  phone
  birthDate
  personalId
  czechSkiId
  fisId
  memberFrom
  memberTo
  variableSymbol
  isActive
  isHidden
}
    `;
export const ProfileFragmentDoc = gql`
    fragment Profile on Profile {
  id
  name
  surname
  phone
  profilePhoto {
    ...File
  }
  members {
    ...Member
  }
}
    ${FileFragmentDoc}
${MemberFragmentDoc}`;
export const MeFragmentDoc = gql`
    fragment Me on Me {
  id
  email
  isActive
  verified
  token
  name
  createdAt
  updatedAt
  lastLoginAt
  roles
  profile {
    ...Profile
  }
}
    ${ProfileFragmentDoc}`;
export const GroupFragmentDoc = gql`
    fragment Group on Group {
  id
  name
}
    `;
export const TrainingWithAttendanceFragmentDoc = gql`
    fragment TrainingWithAttendance on TrainingWithAttendance {
  id
  group {
    ...Group
  }
  datetimeFrom
  durationMinutes
  place
  activity
  attendanceStatus
}
    ${GroupFragmentDoc}`;
export const RaceFragmentDoc = gql`
    fragment Race on Race {
  id
  name
  dateFrom
  dateTo
  description
  url
}
    `;
export const CampFragmentDoc = gql`
    fragment Camp on Camp {
  id
  groups {
    ...Group
  }
  dateFrom
  dateTo
  place
  description
}
    ${GroupFragmentDoc}`;
export const MemberInterestsFragmentDoc = gql`
    fragment MemberInterests on MemberInterests {
  id
  name
  surname
  currentGroup
  trainings {
    ...TrainingWithAttendance
  }
  races {
    ...Race
  }
  camps {
    ...Camp
  }
}
    ${TrainingWithAttendanceFragmentDoc}
${RaceFragmentDoc}
${CampFragmentDoc}`;
export const MyInterestsFragmentDoc = gql`
    fragment MyInterests on MyInterests {
  id
  memberInterests {
    ...MemberInterests
  }
}
    ${MemberInterestsFragmentDoc}`;
export const AttendanceFragmentDoc = gql`
    fragment Attendance on Attendance {
  id
  description
  attendanceStatus
  excusedAt
}
    `;
export const MemberTrainingAttendanceFragmentDoc = gql`
    fragment MemberTrainingAttendance on MemberTrainingAttendance {
  id
  memberId
  trainingId
  name
  surname
  attendance {
    ...Attendance
  }
}
    ${AttendanceFragmentDoc}`;
export const TrainingFragmentDoc = gql`
    fragment Training on Training {
  id
  group {
    ...Group
  }
  datetimeFrom
  durationMinutes
  place
  activity
}
    ${GroupFragmentDoc}`;
export const MemberAttendancesFragmentDoc = gql`
    fragment MemberAttendances on MemberAttendances {
  id
  member {
    ...Member
  }
  attendances {
    ...Attendance
  }
}
    ${MemberFragmentDoc}
${AttendanceFragmentDoc}`;
export const AttendanceReportFragmentDoc = gql`
    fragment AttendanceReport on AttendanceReport {
  id
  trainings {
    ...Training
  }
  memberAttendances {
    ...MemberAttendances
  }
}
    ${TrainingFragmentDoc}
${MemberAttendancesFragmentDoc}`;
export const ImageFragmentDoc = gql`
    fragment Image on Image {
  id
  uuid
  type
  extension
  name
  namespace
  width
  height
}
    `;
export const EventFragmentDoc = gql`
    fragment Event on Event {
  id
  itemOrder
  name
  description
}
    `;
export const SponsorFragmentDoc = gql`
    fragment Sponsor on Sponsor {
  id
  itemOrder
  name
  url
  logo {
    ...File
  }
  mainSponsor
}
    ${FileFragmentDoc}`;
export const TeamFragmentDoc = gql`
    fragment Team on Team {
  id
  itemOrder
  name
  role
  email
  photo {
    ...Image
  }
}
    ${ImageFragmentDoc}`;
export const ConfigurationFragmentDoc = gql`
    fragment Configuration on Configuration {
  id
  name
  officialName
  descriptionMeta
  h1Title
  h1Subtitle
  claim1
  claim2
  claim3
  claim4
  headingImage {
    ...Image
  }
  logo {
    ...File
  }
  addressStreet
  addressCity
  addressZip
  identificationNumber
  bankAccountNumber
  instagram
  facebook
  events {
    ...Event
  }
  sponsors {
    ...Sponsor
  }
  teams {
    ...Team
  }
}
    ${ImageFragmentDoc}
${FileFragmentDoc}
${EventFragmentDoc}
${SponsorFragmentDoc}
${TeamFragmentDoc}`;
export const AddAdvertDocument = gql`
    mutation AddAdvert($title: String!, $description: String!, $phone: String!, $photoId: Int) {
  addAdvert(
    title: $title
    description: $description
    phone: $phone
    photoId: $photoId
  ) {
    ...Advert
  }
}
    ${AdvertFragmentDoc}`;
export type AddAdvertMutationFn = Apollo.MutationFunction<AddAdvertMutation, AddAdvertMutationVariables>;

/**
 * __useAddAdvertMutation__
 *
 * To run a mutation, you first call `useAddAdvertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAdvertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAdvertMutation, { data, loading, error }] = useAddAdvertMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      phone: // value for 'phone'
 *      photoId: // value for 'photoId'
 *   },
 * });
 */
export function useAddAdvertMutation(baseOptions?: Apollo.MutationHookOptions<AddAdvertMutation, AddAdvertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAdvertMutation, AddAdvertMutationVariables>(AddAdvertDocument, options);
      }
export type AddAdvertMutationHookResult = ReturnType<typeof useAddAdvertMutation>;
export type AddAdvertMutationResult = Apollo.MutationResult<AddAdvertMutation>;
export type AddAdvertMutationOptions = Apollo.BaseMutationOptions<AddAdvertMutation, AddAdvertMutationVariables>;
export const DeactivateAdvertDocument = gql`
    mutation DeactivateAdvert($advertId: Int!) {
  deactivateAdvert(advertId: $advertId) {
    ...Advert
  }
}
    ${AdvertFragmentDoc}`;
export type DeactivateAdvertMutationFn = Apollo.MutationFunction<DeactivateAdvertMutation, DeactivateAdvertMutationVariables>;

/**
 * __useDeactivateAdvertMutation__
 *
 * To run a mutation, you first call `useDeactivateAdvertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateAdvertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateAdvertMutation, { data, loading, error }] = useDeactivateAdvertMutation({
 *   variables: {
 *      advertId: // value for 'advertId'
 *   },
 * });
 */
export function useDeactivateAdvertMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateAdvertMutation, DeactivateAdvertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateAdvertMutation, DeactivateAdvertMutationVariables>(DeactivateAdvertDocument, options);
      }
export type DeactivateAdvertMutationHookResult = ReturnType<typeof useDeactivateAdvertMutation>;
export type DeactivateAdvertMutationResult = Apollo.MutationResult<DeactivateAdvertMutation>;
export type DeactivateAdvertMutationOptions = Apollo.BaseMutationOptions<DeactivateAdvertMutation, DeactivateAdvertMutationVariables>;
export const GetAdvertsDocument = gql`
    query GetAdverts($onlyActive: Boolean!) {
  adverts(onlyActive: $onlyActive) {
    ...Advert
  }
}
    ${AdvertFragmentDoc}`;

/**
 * __useGetAdvertsQuery__
 *
 * To run a query within a React component, call `useGetAdvertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdvertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdvertsQuery({
 *   variables: {
 *      onlyActive: // value for 'onlyActive'
 *   },
 * });
 */
export function useGetAdvertsQuery(baseOptions: Apollo.QueryHookOptions<GetAdvertsQuery, GetAdvertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdvertsQuery, GetAdvertsQueryVariables>(GetAdvertsDocument, options);
      }
export function useGetAdvertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdvertsQuery, GetAdvertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdvertsQuery, GetAdvertsQueryVariables>(GetAdvertsDocument, options);
        }
export function useGetAdvertsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAdvertsQuery, GetAdvertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAdvertsQuery, GetAdvertsQueryVariables>(GetAdvertsDocument, options);
        }
export type GetAdvertsQueryHookResult = ReturnType<typeof useGetAdvertsQuery>;
export type GetAdvertsLazyQueryHookResult = ReturnType<typeof useGetAdvertsLazyQuery>;
export type GetAdvertsSuspenseQueryHookResult = ReturnType<typeof useGetAdvertsSuspenseQuery>;
export type GetAdvertsQueryResult = Apollo.QueryResult<GetAdvertsQuery, GetAdvertsQueryVariables>;
export const RegistrationDocument = gql`
    mutation Registration($data: RegistrationInput!) {
  registration(data: $data)
}
    `;
export type RegistrationMutationFn = Apollo.MutationFunction<RegistrationMutation, RegistrationMutationVariables>;

/**
 * __useRegistrationMutation__
 *
 * To run a mutation, you first call `useRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationMutation, { data, loading, error }] = useRegistrationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<RegistrationMutation, RegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegistrationMutation, RegistrationMutationVariables>(RegistrationDocument, options);
      }
export type RegistrationMutationHookResult = ReturnType<typeof useRegistrationMutation>;
export type RegistrationMutationResult = Apollo.MutationResult<RegistrationMutation>;
export type RegistrationMutationOptions = Apollo.BaseMutationOptions<RegistrationMutation, RegistrationMutationVariables>;
export const LoginDocument = gql`
    mutation Login($username: String!, $password: String!) {
  login(username: $username, password: $password) {
    ...AuthResult
  }
}
    ${AuthResultFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const PasswordSetDocument = gql`
    mutation PasswordSet($token: String!, $password: String!) {
  passwordSet(token: $token, password: $password) {
    accessToken
  }
}
    `;
export type PasswordSetMutationFn = Apollo.MutationFunction<PasswordSetMutation, PasswordSetMutationVariables>;

/**
 * __usePasswordSetMutation__
 *
 * To run a mutation, you first call `usePasswordSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordSetMutation, { data, loading, error }] = usePasswordSetMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function usePasswordSetMutation(baseOptions?: Apollo.MutationHookOptions<PasswordSetMutation, PasswordSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PasswordSetMutation, PasswordSetMutationVariables>(PasswordSetDocument, options);
      }
export type PasswordSetMutationHookResult = ReturnType<typeof usePasswordSetMutation>;
export type PasswordSetMutationResult = Apollo.MutationResult<PasswordSetMutation>;
export type PasswordSetMutationOptions = Apollo.BaseMutationOptions<PasswordSetMutation, PasswordSetMutationVariables>;
export const GetContentsDocument = gql`
    query GetContents($limit: Int!, $offset: Int!, $tagCode: String) {
  contents(limit: $limit, offset: $offset, tagCode: $tagCode) {
    ...Content
  }
}
    ${ContentFragmentDoc}`;

/**
 * __useGetContentsQuery__
 *
 * To run a query within a React component, call `useGetContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      tagCode: // value for 'tagCode'
 *   },
 * });
 */
export function useGetContentsQuery(baseOptions: Apollo.QueryHookOptions<GetContentsQuery, GetContentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentsQuery, GetContentsQueryVariables>(GetContentsDocument, options);
      }
export function useGetContentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentsQuery, GetContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentsQuery, GetContentsQueryVariables>(GetContentsDocument, options);
        }
export function useGetContentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetContentsQuery, GetContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetContentsQuery, GetContentsQueryVariables>(GetContentsDocument, options);
        }
export type GetContentsQueryHookResult = ReturnType<typeof useGetContentsQuery>;
export type GetContentsLazyQueryHookResult = ReturnType<typeof useGetContentsLazyQuery>;
export type GetContentsSuspenseQueryHookResult = ReturnType<typeof useGetContentsSuspenseQuery>;
export type GetContentsQueryResult = Apollo.QueryResult<GetContentsQuery, GetContentsQueryVariables>;
export const GetContentDocument = gql`
    query GetContent($contentId: Int!) {
  content(content: $contentId) {
    ...Content
  }
}
    ${ContentFragmentDoc}`;

/**
 * __useGetContentQuery__
 *
 * To run a query within a React component, call `useGetContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentQuery({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useGetContentQuery(baseOptions: Apollo.QueryHookOptions<GetContentQuery, GetContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentQuery, GetContentQueryVariables>(GetContentDocument, options);
      }
export function useGetContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentQuery, GetContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentQuery, GetContentQueryVariables>(GetContentDocument, options);
        }
export function useGetContentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetContentQuery, GetContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetContentQuery, GetContentQueryVariables>(GetContentDocument, options);
        }
export type GetContentQueryHookResult = ReturnType<typeof useGetContentQuery>;
export type GetContentLazyQueryHookResult = ReturnType<typeof useGetContentLazyQuery>;
export type GetContentSuspenseQueryHookResult = ReturnType<typeof useGetContentSuspenseQuery>;
export type GetContentQueryResult = Apollo.QueryResult<GetContentQuery, GetContentQueryVariables>;
export const PairProfilesWithMembersDocument = gql`
    mutation PairProfilesWithMembers($profileIds: [Int!]!, $memberIds: [Int!]!) {
  pairProfilesWithMembers(profileIds: $profileIds, memberIds: $memberIds)
}
    `;
export type PairProfilesWithMembersMutationFn = Apollo.MutationFunction<PairProfilesWithMembersMutation, PairProfilesWithMembersMutationVariables>;

/**
 * __usePairProfilesWithMembersMutation__
 *
 * To run a mutation, you first call `usePairProfilesWithMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePairProfilesWithMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pairProfilesWithMembersMutation, { data, loading, error }] = usePairProfilesWithMembersMutation({
 *   variables: {
 *      profileIds: // value for 'profileIds'
 *      memberIds: // value for 'memberIds'
 *   },
 * });
 */
export function usePairProfilesWithMembersMutation(baseOptions?: Apollo.MutationHookOptions<PairProfilesWithMembersMutation, PairProfilesWithMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PairProfilesWithMembersMutation, PairProfilesWithMembersMutationVariables>(PairProfilesWithMembersDocument, options);
      }
export type PairProfilesWithMembersMutationHookResult = ReturnType<typeof usePairProfilesWithMembersMutation>;
export type PairProfilesWithMembersMutationResult = Apollo.MutationResult<PairProfilesWithMembersMutation>;
export type PairProfilesWithMembersMutationOptions = Apollo.BaseMutationOptions<PairProfilesWithMembersMutation, PairProfilesWithMembersMutationVariables>;
export const UnpairProfileMemberDocument = gql`
    mutation UnpairProfileMember($profileId: Int!, $memberId: Int!) {
  unpairProfileMember(profileId: $profileId, memberId: $memberId)
}
    `;
export type UnpairProfileMemberMutationFn = Apollo.MutationFunction<UnpairProfileMemberMutation, UnpairProfileMemberMutationVariables>;

/**
 * __useUnpairProfileMemberMutation__
 *
 * To run a mutation, you first call `useUnpairProfileMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpairProfileMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpairProfileMemberMutation, { data, loading, error }] = useUnpairProfileMemberMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useUnpairProfileMemberMutation(baseOptions?: Apollo.MutationHookOptions<UnpairProfileMemberMutation, UnpairProfileMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnpairProfileMemberMutation, UnpairProfileMemberMutationVariables>(UnpairProfileMemberDocument, options);
      }
export type UnpairProfileMemberMutationHookResult = ReturnType<typeof useUnpairProfileMemberMutation>;
export type UnpairProfileMemberMutationResult = Apollo.MutationResult<UnpairProfileMemberMutation>;
export type UnpairProfileMemberMutationOptions = Apollo.BaseMutationOptions<UnpairProfileMemberMutation, UnpairProfileMemberMutationVariables>;
export const GetMeDocument = gql`
    query GetMe {
  me {
    ...Me
  }
}
    ${MeFragmentDoc}`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export function useGetMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeSuspenseQueryHookResult = ReturnType<typeof useGetMeSuspenseQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetGroupMembersDocument = gql`
    query GetGroupMembers($groupId: Int!, $groupMemberRole: GroupMemberRole!) {
  groupMembers(groupId: $groupId, groupMemberRole: $groupMemberRole) {
    ...Member
  }
}
    ${MemberFragmentDoc}`;

/**
 * __useGetGroupMembersQuery__
 *
 * To run a query within a React component, call `useGetGroupMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupMembersQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      groupMemberRole: // value for 'groupMemberRole'
 *   },
 * });
 */
export function useGetGroupMembersQuery(baseOptions: Apollo.QueryHookOptions<GetGroupMembersQuery, GetGroupMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupMembersQuery, GetGroupMembersQueryVariables>(GetGroupMembersDocument, options);
      }
export function useGetGroupMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupMembersQuery, GetGroupMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupMembersQuery, GetGroupMembersQueryVariables>(GetGroupMembersDocument, options);
        }
export function useGetGroupMembersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetGroupMembersQuery, GetGroupMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGroupMembersQuery, GetGroupMembersQueryVariables>(GetGroupMembersDocument, options);
        }
export type GetGroupMembersQueryHookResult = ReturnType<typeof useGetGroupMembersQuery>;
export type GetGroupMembersLazyQueryHookResult = ReturnType<typeof useGetGroupMembersLazyQuery>;
export type GetGroupMembersSuspenseQueryHookResult = ReturnType<typeof useGetGroupMembersSuspenseQuery>;
export type GetGroupMembersQueryResult = Apollo.QueryResult<GetGroupMembersQuery, GetGroupMembersQueryVariables>;
export const GetMembersDocument = gql`
    query GetMembers {
  members {
    ...Member
  }
}
    ${MemberFragmentDoc}`;

/**
 * __useGetMembersQuery__
 *
 * To run a query within a React component, call `useGetMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMembersQuery(baseOptions?: Apollo.QueryHookOptions<GetMembersQuery, GetMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMembersQuery, GetMembersQueryVariables>(GetMembersDocument, options);
      }
export function useGetMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMembersQuery, GetMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMembersQuery, GetMembersQueryVariables>(GetMembersDocument, options);
        }
export function useGetMembersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMembersQuery, GetMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMembersQuery, GetMembersQueryVariables>(GetMembersDocument, options);
        }
export type GetMembersQueryHookResult = ReturnType<typeof useGetMembersQuery>;
export type GetMembersLazyQueryHookResult = ReturnType<typeof useGetMembersLazyQuery>;
export type GetMembersSuspenseQueryHookResult = ReturnType<typeof useGetMembersSuspenseQuery>;
export type GetMembersQueryResult = Apollo.QueryResult<GetMembersQuery, GetMembersQueryVariables>;
export const GetMyInterestsDocument = gql`
    query GetMyInterests {
  myInterests {
    ...MyInterests
  }
}
    ${MyInterestsFragmentDoc}`;

/**
 * __useGetMyInterestsQuery__
 *
 * To run a query within a React component, call `useGetMyInterestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyInterestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyInterestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyInterestsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyInterestsQuery, GetMyInterestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyInterestsQuery, GetMyInterestsQueryVariables>(GetMyInterestsDocument, options);
      }
export function useGetMyInterestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyInterestsQuery, GetMyInterestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyInterestsQuery, GetMyInterestsQueryVariables>(GetMyInterestsDocument, options);
        }
export function useGetMyInterestsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyInterestsQuery, GetMyInterestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyInterestsQuery, GetMyInterestsQueryVariables>(GetMyInterestsDocument, options);
        }
export type GetMyInterestsQueryHookResult = ReturnType<typeof useGetMyInterestsQuery>;
export type GetMyInterestsLazyQueryHookResult = ReturnType<typeof useGetMyInterestsLazyQuery>;
export type GetMyInterestsSuspenseQueryHookResult = ReturnType<typeof useGetMyInterestsSuspenseQuery>;
export type GetMyInterestsQueryResult = Apollo.QueryResult<GetMyInterestsQuery, GetMyInterestsQueryVariables>;
export const GetRacesDocument = gql`
    query GetRaces($groupId: Int!) {
  races(groupId: $groupId) {
    ...Race
  }
}
    ${RaceFragmentDoc}`;

/**
 * __useGetRacesQuery__
 *
 * To run a query within a React component, call `useGetRacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRacesQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGetRacesQuery(baseOptions: Apollo.QueryHookOptions<GetRacesQuery, GetRacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRacesQuery, GetRacesQueryVariables>(GetRacesDocument, options);
      }
export function useGetRacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRacesQuery, GetRacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRacesQuery, GetRacesQueryVariables>(GetRacesDocument, options);
        }
export function useGetRacesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetRacesQuery, GetRacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRacesQuery, GetRacesQueryVariables>(GetRacesDocument, options);
        }
export type GetRacesQueryHookResult = ReturnType<typeof useGetRacesQuery>;
export type GetRacesLazyQueryHookResult = ReturnType<typeof useGetRacesLazyQuery>;
export type GetRacesSuspenseQueryHookResult = ReturnType<typeof useGetRacesSuspenseQuery>;
export type GetRacesQueryResult = Apollo.QueryResult<GetRacesQuery, GetRacesQueryVariables>;
export const SetTrainingAttendanceDocument = gql`
    mutation SetTrainingAttendance($trainingId: Int!, $memberId: Int!, $attendanceStatus: AttendanceStatus!) {
  setTrainingAttendance(
    trainingId: $trainingId
    memberId: $memberId
    attendanceStatus: $attendanceStatus
  ) {
    ...MemberTrainingAttendance
  }
}
    ${MemberTrainingAttendanceFragmentDoc}`;
export type SetTrainingAttendanceMutationFn = Apollo.MutationFunction<SetTrainingAttendanceMutation, SetTrainingAttendanceMutationVariables>;

/**
 * __useSetTrainingAttendanceMutation__
 *
 * To run a mutation, you first call `useSetTrainingAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTrainingAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTrainingAttendanceMutation, { data, loading, error }] = useSetTrainingAttendanceMutation({
 *   variables: {
 *      trainingId: // value for 'trainingId'
 *      memberId: // value for 'memberId'
 *      attendanceStatus: // value for 'attendanceStatus'
 *   },
 * });
 */
export function useSetTrainingAttendanceMutation(baseOptions?: Apollo.MutationHookOptions<SetTrainingAttendanceMutation, SetTrainingAttendanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTrainingAttendanceMutation, SetTrainingAttendanceMutationVariables>(SetTrainingAttendanceDocument, options);
      }
export type SetTrainingAttendanceMutationHookResult = ReturnType<typeof useSetTrainingAttendanceMutation>;
export type SetTrainingAttendanceMutationResult = Apollo.MutationResult<SetTrainingAttendanceMutation>;
export type SetTrainingAttendanceMutationOptions = Apollo.BaseMutationOptions<SetTrainingAttendanceMutation, SetTrainingAttendanceMutationVariables>;
export const GetTrainingsDocument = gql`
    query GetTrainings {
  trainings {
    ...Training
  }
}
    ${TrainingFragmentDoc}`;

/**
 * __useGetTrainingsQuery__
 *
 * To run a query within a React component, call `useGetTrainingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrainingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrainingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTrainingsQuery(baseOptions?: Apollo.QueryHookOptions<GetTrainingsQuery, GetTrainingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrainingsQuery, GetTrainingsQueryVariables>(GetTrainingsDocument, options);
      }
export function useGetTrainingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrainingsQuery, GetTrainingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrainingsQuery, GetTrainingsQueryVariables>(GetTrainingsDocument, options);
        }
export function useGetTrainingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTrainingsQuery, GetTrainingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTrainingsQuery, GetTrainingsQueryVariables>(GetTrainingsDocument, options);
        }
export type GetTrainingsQueryHookResult = ReturnType<typeof useGetTrainingsQuery>;
export type GetTrainingsLazyQueryHookResult = ReturnType<typeof useGetTrainingsLazyQuery>;
export type GetTrainingsSuspenseQueryHookResult = ReturnType<typeof useGetTrainingsSuspenseQuery>;
export type GetTrainingsQueryResult = Apollo.QueryResult<GetTrainingsQuery, GetTrainingsQueryVariables>;
export const GetGroupTrainingsDocument = gql`
    query GetGroupTrainings($groupId: Int!) {
  groupTrainings(groupId: $groupId) {
    ...Training
  }
}
    ${TrainingFragmentDoc}`;

/**
 * __useGetGroupTrainingsQuery__
 *
 * To run a query within a React component, call `useGetGroupTrainingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupTrainingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupTrainingsQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGetGroupTrainingsQuery(baseOptions: Apollo.QueryHookOptions<GetGroupTrainingsQuery, GetGroupTrainingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupTrainingsQuery, GetGroupTrainingsQueryVariables>(GetGroupTrainingsDocument, options);
      }
export function useGetGroupTrainingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupTrainingsQuery, GetGroupTrainingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupTrainingsQuery, GetGroupTrainingsQueryVariables>(GetGroupTrainingsDocument, options);
        }
export function useGetGroupTrainingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetGroupTrainingsQuery, GetGroupTrainingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGroupTrainingsQuery, GetGroupTrainingsQueryVariables>(GetGroupTrainingsDocument, options);
        }
export type GetGroupTrainingsQueryHookResult = ReturnType<typeof useGetGroupTrainingsQuery>;
export type GetGroupTrainingsLazyQueryHookResult = ReturnType<typeof useGetGroupTrainingsLazyQuery>;
export type GetGroupTrainingsSuspenseQueryHookResult = ReturnType<typeof useGetGroupTrainingsSuspenseQuery>;
export type GetGroupTrainingsQueryResult = Apollo.QueryResult<GetGroupTrainingsQuery, GetGroupTrainingsQueryVariables>;
export const GetGroupCampsDocument = gql`
    query GetGroupCamps($groupId: Int!) {
  groupCamps(groupId: $groupId) {
    ...Camp
  }
}
    ${CampFragmentDoc}`;

/**
 * __useGetGroupCampsQuery__
 *
 * To run a query within a React component, call `useGetGroupCampsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupCampsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupCampsQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGetGroupCampsQuery(baseOptions: Apollo.QueryHookOptions<GetGroupCampsQuery, GetGroupCampsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupCampsQuery, GetGroupCampsQueryVariables>(GetGroupCampsDocument, options);
      }
export function useGetGroupCampsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupCampsQuery, GetGroupCampsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupCampsQuery, GetGroupCampsQueryVariables>(GetGroupCampsDocument, options);
        }
export function useGetGroupCampsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetGroupCampsQuery, GetGroupCampsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGroupCampsQuery, GetGroupCampsQueryVariables>(GetGroupCampsDocument, options);
        }
export type GetGroupCampsQueryHookResult = ReturnType<typeof useGetGroupCampsQuery>;
export type GetGroupCampsLazyQueryHookResult = ReturnType<typeof useGetGroupCampsLazyQuery>;
export type GetGroupCampsSuspenseQueryHookResult = ReturnType<typeof useGetGroupCampsSuspenseQuery>;
export type GetGroupCampsQueryResult = Apollo.QueryResult<GetGroupCampsQuery, GetGroupCampsQueryVariables>;
export const GetGroupsDocument = gql`
    query GetGroups {
  groups {
    ...Group
  }
}
    ${GroupFragmentDoc}`;

/**
 * __useGetGroupsQuery__
 *
 * To run a query within a React component, call `useGetGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetGroupsQuery, GetGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupsQuery, GetGroupsQueryVariables>(GetGroupsDocument, options);
      }
export function useGetGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupsQuery, GetGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupsQuery, GetGroupsQueryVariables>(GetGroupsDocument, options);
        }
export function useGetGroupsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetGroupsQuery, GetGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGroupsQuery, GetGroupsQueryVariables>(GetGroupsDocument, options);
        }
export type GetGroupsQueryHookResult = ReturnType<typeof useGetGroupsQuery>;
export type GetGroupsLazyQueryHookResult = ReturnType<typeof useGetGroupsLazyQuery>;
export type GetGroupsSuspenseQueryHookResult = ReturnType<typeof useGetGroupsSuspenseQuery>;
export type GetGroupsQueryResult = Apollo.QueryResult<GetGroupsQuery, GetGroupsQueryVariables>;
export const GetTrainingAttendancesDocument = gql`
    query GetTrainingAttendances($trainingId: Int!) {
  trainingAttendances(trainingId: $trainingId) {
    ...MemberTrainingAttendance
  }
}
    ${MemberTrainingAttendanceFragmentDoc}`;

/**
 * __useGetTrainingAttendancesQuery__
 *
 * To run a query within a React component, call `useGetTrainingAttendancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrainingAttendancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrainingAttendancesQuery({
 *   variables: {
 *      trainingId: // value for 'trainingId'
 *   },
 * });
 */
export function useGetTrainingAttendancesQuery(baseOptions: Apollo.QueryHookOptions<GetTrainingAttendancesQuery, GetTrainingAttendancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrainingAttendancesQuery, GetTrainingAttendancesQueryVariables>(GetTrainingAttendancesDocument, options);
      }
export function useGetTrainingAttendancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrainingAttendancesQuery, GetTrainingAttendancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrainingAttendancesQuery, GetTrainingAttendancesQueryVariables>(GetTrainingAttendancesDocument, options);
        }
export function useGetTrainingAttendancesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTrainingAttendancesQuery, GetTrainingAttendancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTrainingAttendancesQuery, GetTrainingAttendancesQueryVariables>(GetTrainingAttendancesDocument, options);
        }
export type GetTrainingAttendancesQueryHookResult = ReturnType<typeof useGetTrainingAttendancesQuery>;
export type GetTrainingAttendancesLazyQueryHookResult = ReturnType<typeof useGetTrainingAttendancesLazyQuery>;
export type GetTrainingAttendancesSuspenseQueryHookResult = ReturnType<typeof useGetTrainingAttendancesSuspenseQuery>;
export type GetTrainingAttendancesQueryResult = Apollo.QueryResult<GetTrainingAttendancesQuery, GetTrainingAttendancesQueryVariables>;
export const GetGroupMonthAttendanceReportDocument = gql`
    query GetGroupMonthAttendanceReport($groupId: Int!, $year: Int!, $month: Int!) {
  groupMonthAttendanceReport(groupId: $groupId, year: $year, month: $month) {
    ...AttendanceReport
  }
}
    ${AttendanceReportFragmentDoc}`;

/**
 * __useGetGroupMonthAttendanceReportQuery__
 *
 * To run a query within a React component, call `useGetGroupMonthAttendanceReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupMonthAttendanceReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupMonthAttendanceReportQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      year: // value for 'year'
 *      month: // value for 'month'
 *   },
 * });
 */
export function useGetGroupMonthAttendanceReportQuery(baseOptions: Apollo.QueryHookOptions<GetGroupMonthAttendanceReportQuery, GetGroupMonthAttendanceReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupMonthAttendanceReportQuery, GetGroupMonthAttendanceReportQueryVariables>(GetGroupMonthAttendanceReportDocument, options);
      }
export function useGetGroupMonthAttendanceReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupMonthAttendanceReportQuery, GetGroupMonthAttendanceReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupMonthAttendanceReportQuery, GetGroupMonthAttendanceReportQueryVariables>(GetGroupMonthAttendanceReportDocument, options);
        }
export function useGetGroupMonthAttendanceReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetGroupMonthAttendanceReportQuery, GetGroupMonthAttendanceReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGroupMonthAttendanceReportQuery, GetGroupMonthAttendanceReportQueryVariables>(GetGroupMonthAttendanceReportDocument, options);
        }
export type GetGroupMonthAttendanceReportQueryHookResult = ReturnType<typeof useGetGroupMonthAttendanceReportQuery>;
export type GetGroupMonthAttendanceReportLazyQueryHookResult = ReturnType<typeof useGetGroupMonthAttendanceReportLazyQuery>;
export type GetGroupMonthAttendanceReportSuspenseQueryHookResult = ReturnType<typeof useGetGroupMonthAttendanceReportSuspenseQuery>;
export type GetGroupMonthAttendanceReportQueryResult = Apollo.QueryResult<GetGroupMonthAttendanceReportQuery, GetGroupMonthAttendanceReportQueryVariables>;
export const GetConfigurationDocument = gql`
    query GetConfiguration {
  configuration {
    ...Configuration
  }
}
    ${ConfigurationFragmentDoc}`;

/**
 * __useGetConfigurationQuery__
 *
 * To run a query within a React component, call `useGetConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConfigurationQuery(baseOptions?: Apollo.QueryHookOptions<GetConfigurationQuery, GetConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConfigurationQuery, GetConfigurationQueryVariables>(GetConfigurationDocument, options);
      }
export function useGetConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConfigurationQuery, GetConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConfigurationQuery, GetConfigurationQueryVariables>(GetConfigurationDocument, options);
        }
export function useGetConfigurationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetConfigurationQuery, GetConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetConfigurationQuery, GetConfigurationQueryVariables>(GetConfigurationDocument, options);
        }
export type GetConfigurationQueryHookResult = ReturnType<typeof useGetConfigurationQuery>;
export type GetConfigurationLazyQueryHookResult = ReturnType<typeof useGetConfigurationLazyQuery>;
export type GetConfigurationSuspenseQueryHookResult = ReturnType<typeof useGetConfigurationSuspenseQuery>;
export type GetConfigurationQueryResult = Apollo.QueryResult<GetConfigurationQuery, GetConfigurationQueryVariables>;